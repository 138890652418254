import '../styles/Gallery.css';
import { useState } from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Typography } from '@mui/material';
import "../styles/carousel.css";

interface GalleryProps {
    screenType: string;
}

export default function Gallery({screenType}: GalleryProps) {
    const GALLERY_IMAGE_PATH = 'images/gallery/';
    const [imageUrls] = useState([
        'mw1.jpg',
        'mw2.jpg',
        'mw3.jpg',
        'mw4.jpg',
        'mw5.jpg',
        'mw6.jpg',
        'mw7.jpg',
        'mw8.png',
        'mw9.jpg',
        'mw10.jpg',
        'mw11.jpg',
        'mw12.jpg',
        'mw13.jpg',
    ])
    // const [favoriateImageUrls] = useState([
    //     'mw9.jpg',
    //     'mw10.jpg',
    //     'mw11.jpg',
    //     'mw12.jpg',
    //     'mw13.jpg',
    // ])

    let numberOfColumns = 5;
        let imageGap = 10;
        if(screenType === 'tablet')
        {
            numberOfColumns = 3;
            imageGap = 5;
        }
        else if(screenType === 'mobile')
        {
            numberOfColumns = 2;
            imageGap = 2;
        }

        return (
            <Box className="galleryContainer">
                <Box className="galleryImagesContainer">
                    <Typography variant='h1' component='div'>
                        Customer's Photos
                    </Typography>
                    <ImageList id="galleryImageList" cols={numberOfColumns} rowHeight={'auto'} gap={imageGap}>
                        {imageUrls.map((fileName) => {
                            let url = GALLERY_IMAGE_PATH + fileName;
                            return (
                                <ImageListItem key={url}>
                                    <img
                                        src={`${url}`}
                                        srcSet={`${url}`}
                                        alt=""
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            )
                        })}
                    </ImageList>
                </Box>
            </Box>
        );
}
