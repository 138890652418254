import '../styles/Home.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import MyButton from './MyButton';

export default function Home() {
    let [info] = useState({
        slogan: 'We Have The Skills You Will Love',
        sloganFollowup: 'Welcome to M & W Nail',
        largeText: 'We Are Open',
        largeTextFollowup: 'No appointments required',
    });

    return (
        <Box className="homeContainer">
            <Box className="homeSection">
                <Typography variant='h1' component='div'>{info.slogan}</Typography>
                <Typography variant='body1' component='div'>{info.sloganFollowup}</Typography>
            </Box>
            <Box className="homeSection">
                <Typography variant='h1' component='div'>{info.largeText}</Typography>
                <Typography variant='body1' component='div'>{info.largeTextFollowup}</Typography>
            </Box>
            
            <Box className="homeSection">
                <Link to="/services" style={{
                            textDecoration: "none",
                        }}>
                    <MyButton style={{width: '249px', padding: '16px'}}>
                        View Services
                    </MyButton>
                </Link>
                
                <Link to="/gallery" style={{
                            textDecoration: "none",
                        }}>
                    <MyButton style={{width: '249px', padding: '16px'}}>
                        Gallery
                    </MyButton>
                </Link>
            </Box>
        </Box>
    );
}
