import "../styles/Review.css";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Stars from "./Stars";

interface ReviewProps {
    name: string;
    review: string;
    rating: number;
}

export default function Review({name, review, rating}: ReviewProps) {
    return (
        <Box className="reviewContainer">
            <Typography variant='subtitle1' component='div'>{name}</Typography>
            <Stars stars={rating} />
            <Typography variant='body1'>{review}</Typography>
        </Box>
    );
}

