import '../styles/Footer.css';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function Footer() {

    let [info] = useState({
        addressTitle: "Address",
        addressLine1: "77 Avenue C",
        addressLine2: "New York, NY 10009",
        hoursTitle: "Business Hours",
        hoursLine1: "Monday: Closed",
        hoursLine2: "Tuesday-Saturday: 10AM-7:30PM",
        hoursLine3: "Sunday: 10AM-7PM",
        contactTitle: "Contact",
        contact: "(212) 387-9470",
        copyrightText: "Copyright © 2023 M & W Nail. All rights reserved."
    })

    return (
        <Box className="footerContainer">
            <Box className="footerTop">
                <Box className="footerSection">
                    <Typography variant='h3' component='div'>{info.addressTitle}</Typography>
                    <Typography variant='body2' component='div'>{info.addressLine1}</Typography>
                    <Typography variant='body2' component='div'>{info.addressLine2}</Typography>
                </Box>
                <Box className="footerSection">
                    <Typography variant='h3' component='div'>{info.hoursTitle}</Typography>
                    <Typography variant='body2' component='div'>{info.hoursLine1}</Typography>
                    <Typography variant='body2' component='div'>{info.hoursLine2}</Typography>
                    <Typography variant='body2' component='div'>{info.hoursLine3}</Typography>
                </Box>
                <Box className="footerSection">
                    <Typography variant='h3' component='div'>{info.contactTitle}</Typography>
                    <Typography variant='body2' component='div'>{info.contact}</Typography>
                </Box>
            </Box>
            <Box className="footerBottom">
                <Typography variant="body2" component="div">
                    {info.copyrightText}
                </Typography>
            </Box>
        </Box>
    );
}
