import "../styles/Stars.css";
import Box from '@mui/material/Box';
import { palette } from '../styles/palettes.js';

interface StarsProps {
    stars: number;
}

export default function Stars({stars}: StarsProps) {

    const createStars = (count: number) => {
        let numOfStars = count;
        let starsCreated = 0;
        let starComponents = [];
        while(starsCreated < 5)
        {
            if(numOfStars <= 0)
                starComponents.push(starEmpty);
            else if(numOfStars > 0 && numOfStars < 1)
                starComponents.push(starHalf);
            else
                starComponents.push(starFull);
            starsCreated++;
            numOfStars--;
        }
        
        return starComponents.map((star, idx) => {
            return <Box key={idx}>{star}</Box>
        });
    }

    return (
        <Box className='starsContainer'>
            {createStars(stars)}
        </Box>
    );
}




const starFull = (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5383 2.41195C15.7091 2.0013 16.2909 2.0013 16.4617 2.41194L19.5358 9.80313C19.7518 10.3225 20.2403 10.6773 20.8009 10.7223L28.7803 11.362C29.2237 11.3975 29.4034 11.9508 29.0657 12.2401L22.9862 17.4478C22.559 17.8138 22.3725 18.3879 22.503 18.9351L24.3603 26.7216C24.4635 27.1542 23.9929 27.4962 23.6134 27.2643L16.7819 23.0917C16.3019 22.7985 15.6981 22.7985 15.2181 23.0917L8.38663 27.2643C8.00708 27.4962 7.53646 27.1542 7.63965 26.7216L9.49703 18.9351C9.62754 18.3879 9.44098 17.8138 9.0138 17.4478L2.93433 12.2401C2.59656 11.9508 2.77633 11.3975 3.21965 11.362L11.1991 10.7223C11.7597 10.6773 12.2482 10.3225 12.4642 9.80313L15.5383 2.41195Z" fill={palette.primary[500]} stroke={palette.primary[900]}/>
    </svg>
)

const starHalf = (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5383 2.41195C15.7091 2.0013 16.2909 2.0013 16.4617 2.41194L19.5358 9.80313C19.7518 10.3225 20.2403 10.6773 20.8009 10.7223L28.7803 11.362C29.2237 11.3975 29.4034 11.9508 29.0657 12.2401L22.9862 17.4478C22.559 17.8138 22.3725 18.3879 22.503 18.9351L24.3603 26.7216C24.4635 27.1542 23.9929 27.4962 23.6134 27.2643L16.7819 23.0917C16.3019 22.7985 15.6981 22.7985 15.2181 23.0917L8.38663 27.2643C8.00708 27.4962 7.53646 27.1542 7.63965 26.7216L9.49703 18.9351C9.62754 18.3879 9.44098 17.8138 9.0138 17.4478L2.93433 12.2401C2.59656 11.9508 2.77633 11.3975 3.21965 11.362L11.1991 10.7223C11.7597 10.6773 12.2482 10.3225 12.4642 9.80313L15.5383 2.41195Z" fill="url(#paint0_linear_21_210)" stroke={palette.primary[900]}/>
        <defs>
            <linearGradient id="paint0_linear_21_210" x1="0" y1="16" x2="32" y2="16" gradientUnits="userSpaceOnUse">
                <stop offset="0.529167" stop-color={palette.primary[500]}/>
                <stop offset="0.570833" stop-color={palette.primary[500]} stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
)

const starEmpty = (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5383 2.41195C15.7091 2.0013 16.2909 2.0013 16.4617 2.41194L19.5358 9.80313C19.7518 10.3225 20.2403 10.6773 20.8009 10.7223L28.7803 11.362C29.2237 11.3975 29.4034 11.9508 29.0657 12.2401L22.9862 17.4478C22.559 17.8138 22.3725 18.3879 22.503 18.9351L24.3603 26.7216C24.4635 27.1542 23.9929 27.4962 23.6134 27.2643L16.7819 23.0917C16.3019 22.7985 15.6981 22.7985 15.2181 23.0917L8.38663 27.2643C8.00708 27.4962 7.53646 27.1542 7.63965 26.7216L9.49703 18.9351C9.62754 18.3879 9.44098 17.8138 9.0138 17.4478L2.93433 12.2401C2.59656 11.9508 2.77633 11.3975 3.21965 11.362L11.1991 10.7223C11.7597 10.6773 12.2482 10.3225 12.4642 9.80313L15.5383 2.41195Z" stroke={palette.primary[900]}/>
    </svg>
)