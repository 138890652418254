import Box from '@mui/material/Box';
import { palette } from '../styles/palettes.js';
import { Typography } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

interface MyButtonProps {
    children: ReactNode;
    style: CSSProperties;
}

export default function MyButton({children, style}: MyButtonProps) {
    return (
        <Box style={style} sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            backgroundColor:palette.primary[500],
            borderRadius:'8px',
        }}>
            <Typography variant='body1' component='div' sx={{
                color:palette.neutral[700],
                textAlign:'center',
            }}>
                {children}
            </Typography>
        </Box>
    );
}
