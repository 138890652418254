
export const fontstyles = {
    heading: {
        h1: {
            fontFamily: "Lora",
            fontSize: 40,
            lineHeight: "125%",
            fontWeight: 600
        },
        h2: {
            fontFamily: "Lora",
            fontSize: 32,
            lineHeight: "125%",
            fontWeight: 600
        },
        h3: {
            fontFamily: "Lora",
            fontSize: 24,
            lineHeight: "125%",
            fontWeight: 600
        },
        h4: {
            fontFamily: "Lora",
            fontSize: 18,
            lineHeight: "125%",
            fontWeight: 600
        },
        h5: {
            fontFamily: "Lora",
            fontSize: 14,
            lineHeight: "125%",
            fontWeight: 600
        },
    },
    body: {
        regular: {
            "16px": {
                fontFamily: "Inter",
                fontSize: 16,
                lineHeight: "150%",
                fontWeight: 400
            },
            "14px": {
                fontFamily: "Inter",
                fontSize: 14,
                lineHeight: "150%",
                fontWeight: 400
            },
            "12px": {
                fontFamily: "Inter",
                fontSize: 12,
                lineHeight: "150%",
                fontWeight: 400
            },
        },
        bold: {
            "16px": {
                fontFamily: "Inter",
                fontSize: 16,
                lineHeight: "150%",
                fontWeight: 500
            },
            "14px": {
                fontFamily: "Inter",
                fontSize: 14,
                lineHeight: "150%",
                fontWeight: 500
            },
            "12px": {
                fontFamily: "Inter",
                fontSize: 12,
                lineHeight: "150%",
                fontWeight: 500
            },
        }
    },
    label: {
        regular: {
            "16px": {
                fontFamily: "Inter",
                fontSize: 16,
                lineHeight: "125%",
                fontWeight: 400
            },
            "14px": {
                fontFamily: "Inter",
                fontSize: 14,
                lineHeight: "125%",
                fontWeight: 400
            },
            "12px": {
                fontFamily: "Inter",
                fontSize: 12,
                lineHeight: "125%",
                fontWeight: 400
            },
        },
        bold: {
            "16px": {
                fontFamily: "Inter",
                fontSize: 16,
                lineHeight: "125%",
                fontWeight: 500
            },
            "14px": {
                fontFamily: "Inter",
                fontSize: 14,
                lineHeight: "125%",
                fontWeight: 500
            },
            "12px": {
                fontFamily: "Inter",
                fontSize: 12,
                lineHeight: "125%",
                fontWeight: 500
            },
        }
    }
}