import "../styles/About.css";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Review from "./Review";
import Schedule from "./Schedule";

interface IReview {
    name: string;
    review: string;
    rating: number;
}

export default function About() {

    const [hoursTitle] = useState('Our Working Hours');
    const [days] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    const [time] = useState(['10AM-7PM', 'Closed', '10AM-7:30PM', '10AM-7:30PM', '10AM-7:30PM', '10AM-7:30PM', '10AM-7:30PM']);
    const [locationTitle] = useState('Location');
    const [addressLine1] = useState('77 Avenue C');
    const [addressLine2] = useState('New York, NY 10009');
    const [reviewsTitle] = useState('Thanks for the kind reviews from our customers');
    const [reviews] = useState<IReview[]>([
        {
            name: "s a.",
            rating: 5,
            review: `I followed the nail salon owner, Angie, from her previous job to this new salon because she is as good as it gets. She does an impeccable job- a real perfectionist and professional. I feel so strongly about her that I am the one adding this business to Yelp since her business isn't online in any way. Mani/pedis Mon-Thurs are $20! And around $23 during the other days. Gels are $22 (unheard of in NYC- and they give you quick back rub while your topcoat cements!). They have all new furniture and the place is clean and organized, but this is in no way a spa- it is your classic NYC nail salon.

            NOTE: There was a nail salon at this location prior to Angie taking over- and the former nail salon was filthy and outdated and the manicurists were very sloppy. This is a brand new salon now.
            
            `,
        },
        {
            name: "Caitlin Frackelton",
            rating: 5,
            review: `I *love* this place. Been trying for years to kick a nail-biting habit and these guys have been amazing at helping me reform. Service is excellent, and the regular mani's last forever. I'm really rough on my hands, and even I get a week out of them. Incredible! The folks who work here are so nice, and really go out of their way to make your nails look as great as possible...have never before seen such fastidious cuticle care. I come here every week.`
        },
        {
            name: 'A Montano',
            rating: 5,
            review: `Went in and asked for short coffin acrylic nails (+$2 for shape). It was such a great experience. The workers are very kind and ask how you feel about your nails through the process. Definitely got exactly what I wanted. Wait time was a little long +20 minutes but I highly recommend.`,
        },
        {
            name: 'Gina A.',
            rating: 5,
            review: `I've always have a good experience with this salon.The owners are lovely and the staff are very experienced. No complaints after coming here for several years. If you have any issues they're willing to accommodate you.`
        },
        {
            name: 'William Bray',
            rating: 5,
            review: `We live in Soho where there are nail salons everywhere but they charge an arm and a leg where this place is reasonable and they do an even better job my wife loves this place`
        }
    ])

    useEffect(() => {
        // Load google maps.

    },[])

    const createReviews = (reviews: IReview[]) => {
        let reviewRows = [];
        for(let i = 0; i < reviews.length; i+=2)
        {
            let col1 = reviews[i];
            let col2 = null;
            if(i + 1 < reviews.length)
                col2 = reviews[i + 1];
            reviewRows.push(
                <Box key={col1.name} className="aboutReviewsRow">
                    <Review 
                        name={col1.name}
                        rating={col1.rating}
                        review={col1.review}
                    />
                    {col2 ? <Review 
                        name={col2.name}
                        rating={col2.rating}
                        review={col2.review}
                    /> : null}
                </Box>
            )
        }
        return reviewRows;
    }
    
    const mapIcon = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 1C7.81314 1 7.63825 1.05125 7.48862 1.14047L0.503861 5.13176C0.192286 5.3098 0 5.64114 0 6V22C0 22.3565 0.189758 22.686 0.498073 22.8649C0.806388 23.0438 1.18664 23.0451 1.49614 22.8682L8.03147 19.1338L15.5348 22.8854C15.6646 22.9538 15.8112 22.9944 15.9669 22.9995C15.9779 22.9998 15.989 23 16 23C16.1869 23 16.3618 22.9487 16.5114 22.8595L23.4961 18.8682C23.8077 18.6902 24 18.3589 24 18V2C24 1.64353 23.8102 1.31401 23.5019 1.13509C23.1936 0.956168 22.8134 0.954898 22.5039 1.13176L15.9685 4.86623L8.4652 1.11457C8.32855 1.04262 8.17321 1.00139 8.00837 1.00003L8 1ZM17 20.2768L22 17.4197V3.72318L17 6.58032V20.2768ZM15 6.61803L9 3.61803V17.382L15 20.382V6.61803ZM2 6.58032L7 3.72318V17.4197L2 20.2768V6.58032Z" fill="#2D3648"/>
        </svg>
    )

    return (
        <Box className="aboutContainer">
            <Box className="aboutHoursAndLocationContainer">
                <Schedule title={hoursTitle} days={days} time={time} />
                <Box className="aboutLocationContainer">
                    <Typography variant='h2' component='div'>{locationTitle}</Typography>
                        {mapIcon}
                    <Box className="aboutMapAndAddressContainer">
                        <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.7637334559818!2d-73.98186658403728!3d40.72321737933059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25979b247c959%3A0xbcd158a764f88d4d!2s77%20Loisaida%20Ave%2C%20New%20York%2C%20NY%2010009!5e0!3m2!1sen!2sus!4v1670299034785!5m2!1sen!2sus" max-width="100%" height="300" style={{border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <Typography variant='body1' component='div'>{addressLine1}</Typography>
                        <Typography variant='body1' component='div'>{addressLine2}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box className="aboutReviewsContainer">
                <Typography variant='h2' component='div'>{reviewsTitle}</Typography>
                {createReviews(reviews)}  
            </Box>
        </Box>
    );
}
