
export const palette = {
    primary: {
        900: "#F35399",
        500: "#FC7FBB",
        100: "#FFB8DE",
    },
    neutral: {
        900: "#1A1114",
        700: "#352B2F",
        500: "#62585C",
        300: "#9B9598",
        100: "#DBD6D8",
        white: "#F4F4F4",
    }
}
