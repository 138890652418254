import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fontstyles } from './styles/fontstyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Services from './components/Services';
import About from './components/About';

const myTheme = {
  body16pxr: fontstyles.body.regular['16px'],
  body14pxr: fontstyles.body.regular['14px'],
  body12pxr: fontstyles.body.regular['12px'],
  body16pxb: fontstyles.body.bold['16px'],
  body14pxb: fontstyles.body.bold['14px'],
  body12pxb: fontstyles.body.bold['12px'],
  label16pxr: fontstyles.label.regular['16px'],
  label14pxr: fontstyles.label.regular['14px'],
  label12pxr: fontstyles.label.regular['12px'],
  label16pxb: fontstyles.label.bold['16px'],
  label14pxb: fontstyles.label.bold['14px'],
  label12pxb: fontstyles.label.bold['12px'],
}

const theme = createTheme({
  typography: {
    h1: fontstyles.heading.h1,
    h2: fontstyles.heading.h2,
    h3: fontstyles.heading.h3,
    h4: fontstyles.heading.h4,
    h5: fontstyles.heading.h5,
    body1: myTheme.body16pxr,
    body2: myTheme.body12pxr,
    subtitle1: myTheme.label16pxb,
    subtitle2: myTheme.label12pxb,
  },
  breakpoints: {
    values: {
      xs: 0, // mobile
      sm: 600,
      md: 899, // tablet
      lg: 1200,
      xl: 1440, // desktop
    }
  }
});

interface AppProps {
  page: string;
}

function getPage(page: string, screenType: string) {
  switch(page) {
    case 'home': return <Home />;
    case 'gallery': return <Gallery screenType={screenType}/>;
    case 'services': return <Services/>;
    case 'about': return <About />;
    default: return <Home />;
  }
}

function App({page}: AppProps) {
  let screenType = 'desktop';
  if(useMediaQuery(theme.breakpoints.between('xs', 'md')))
    screenType = 'mobile';
  if(useMediaQuery(theme.breakpoints.between('md', 'xl')))
    screenType = 'tablet';
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Navbar />
          {getPage(page, screenType)}
        <Footer />
      </ThemeProvider>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
