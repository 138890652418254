import '../styles/ServiceItem.css';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

interface ServiceItemProps {
    name: string;
    price: number;
}

export default function ServiceItem({name, price}: ServiceItemProps) {
    return (
        <Box className='serviceItemContainer'>
            <Typography className='serviceItemName' variant='subtitle1' component='div'>{name}</Typography>
            <Box className='serviceItemDashedLine'></Box>
            <Typography className='serviceItemPrice' variant='subtitle1' component='div'>${price}</Typography>
        </Box>
    );
}
