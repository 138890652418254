import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import "../styles/Schedule.css";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

interface ScheduleProps {
    title: string;
    days: string[];
    time: string[];
}

interface ScheduleRow {
    day: string;
    time: string;
}

export default function Schedule({title, days, time}: ScheduleProps) {

    const calendarIcon = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2V3H9V2C9 1.44772 8.55228 1 8 1C7.44772 1 7 1.44772 7 2V3H5C3.34315 3 2 4.34315 2 6V20C2 21.6569 3.34315 23 5 23H19C20.6569 23 22 21.6569 22 20V6C22 4.34315 20.6569 3 19 3H17V2ZM20 9V6C20 5.44772 19.5523 5 19 5H17V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V5H9V6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6V5H5C4.44772 5 4 5.44772 4 6V9H20ZM4 11H20V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V11Z" fill="#2D3648"/>
        </svg>
    )

    const [rows, setRows] = useState<ScheduleRow[]>([]);

    useEffect(() => {
        let newRows: ScheduleRow[] = [];
        for(let i = 0; i < days.length; i++) {
                newRows.push({
                day: days[i],
                time: time[i],
            });
        }
        setRows(newRows);
    }, [days, time]);

    return (
        <TableContainer>
            <Table aria-label="schedule table" sx={{minWidth: 320}}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                            {calendarIcon}
                            <Typography component='span' variant="h2" sx={{paddingLeft: "10px"}}>
                                Our Schedule
                            </Typography> 
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.day}
                        >
                            <TableCell>
                                {row.day}
                            </TableCell>
                            <TableCell>
                                {row.time}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer> 
    )

}

